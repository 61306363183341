<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <!-- <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2> -->
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav flex-nowrap align-items-center" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item d-xl-none mr-1 mr-xl-0">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
      <li class="nav-item">
        <!-- Search -->
        <!-- <app-navbar-search></app-navbar-search> -->
        <!--/ Search -->
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Bookmark -->
    <!-- <app-navbar-bookmark></app-navbar-bookmark> -->
    <!--/ Bookmark -->
  </div>
  <ul class="nav navbar-nav align-items-center ml-auto flex-nowrap">
    <!-- Language selection -->
    <!-- <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i><span
          class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a>
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li> -->
    <!--/ Language selection -->

    <!-- Toggle skin -->
    <!-- <li class="nav-item d-none d-lg-block">
      <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li> -->
    <!--/ Toggle skin -->



    <!-- Cart -->
    <!-- <app-navbar-cart></app-navbar-cart> -->
    <!--/ Cart -->

    <!-- Notification -->
    <app-navbar-notification></app-navbar-notification>
    <!--/ Notification -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user mb-sm-25 ml-sm-1 border-left">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <!-- <ng-container *ngIf="this.currentUser"> -->
        <ng-container>

          <div class="user-nav d-sm-flex d-none align-items-end mr-50">
            <span class="user-name font-weight-normal fs-4">
              <!-- MM000001 : Momo Admins -->
              {{ this.currentUser?.admin_code }} : {{ this.currentUser?.name }}
              <!-- {{ this.currentUser.firstName }} {{ this.currentUser.lastName}} -->
            </span>
            <!-- <span class="user-status bg-white rounded-pill fs-6"><span class="text-primary px-75 py-25">Super
                Admin</span></span> -->
            <span class="user-status fs-6">{{ this.currentUser?.role.name }}</span>
          </div>
          <span class="avatar mx-75">
            <!-- <img class="round"
              src="assets/images/portrait/small/{{ this.currentUser.avatar != '' ? this.currentUser.avatar:'0.png' }}"
              alt="avatar" height="40" width="40" /> -->

            <div class="avatar-content">
              <img src="assets/images/avatars/4.png" width="44" height="44" alt="">
            </div>

            <!-- <img *ngIf="this.currentUser.avatar != ''" class="round"
              src="assets/images/portrait/small/{{ this.currentUser.avatar }}" alt="avatar" height="40" width="40" />

            <div *ngIf="this.currentUser.avatar == ''" class="avatar-content">
              {{ this.currentUser.name | initials }}
            </div> -->

            <!-- <span class="avatar-status-online"></span> -->
          </span>
          <i class="far fa-chevron-down fs-6 ml-50 text-gray"></i>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <!-- <a ngbDropdownItem [routerLink]="['/pages/profile']">
          <span [data-feather]="'user'" [class]="'mr-50'"></span>
          Profile
        </a> -->
        <!-- <a ngbDropdownItem [routerLink]="['/apps/email']">
          <span [data-feather]="'mail'" [class]="'mr-50'"></span>
          Inbox
        </a>
        <a ngbDropdownItem [routerLink]="['/apps/todo']">
          <span [data-feather]="'check-square'" [class]="'mr-50'"></span>
          Task
        </a>
        <a ngbDropdownItem [routerLink]="['/apps/chat']">
          <span [data-feather]="'message-square'" [class]="'mr-50'"></span>
          Chats
        </a> -->
        <!-- <div class="dropdown-divider"></div> -->
        <!-- <a class="fs-4 d-flex" ngbDropdownItem [routerLink]="['/profile']">
          <span [data-feather]="'user'" [class]="'mr-50 align-self-center'"></span>
          Profile
        </a> -->
        <!-- <a class="fs-4 d-flex" ngbDropdownItem [routerLink]="['/pages/account-settings']">
          <span [data-feather]="'settings'" [class]="'mr-50 align-self-center'"></span>
          Settings
        </a> -->
        <!-- <a ngbDropdownItem [routerLink]="['/pages/pricing']">
          <span [data-feather]="'credit-card'" [class]="'mr-50'"> </span>
          Pricing
        </a>
        <a ngbDropdownItem [routerLink]="['/pages/faq']">
          <span [data-feather]="'help-circle'" [class]="'mr-50'">
          </span>
          FAQ
        </a> -->
        <a class="d-flex fs-4" ngbDropdownItem (click)="logout()">
          <span [data-feather]="'power'" [class]="'mr-50 align-self-center'"></span>
          Logout</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>