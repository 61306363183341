import { NameByIdPipe } from './name-by-id.pipe';
import { CreditDebitMaskPipePipe } from './credit-debit-mask.pipe';
import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';

import { InitialsPipe } from '@core/pipes/initials.pipe';

import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';


@NgModule({
  declarations: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, CreditDebitMaskPipePipe, NameByIdPipe],
  imports: [],
  exports: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, CreditDebitMaskPipePipe, NameByIdPipe]
})
export class CorePipesModule { }
