import { Injectable } from '@angular/core';
// import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ActivatedRouteSnapshot, CanActivate, NavigationStart, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'app/auth/service';
import { UserService } from 'app/auth/service';
import { menu } from 'app/menu/menu';
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  menus = menu;
  url: any;
  user: any;
  subPermissionUrl: any;

  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService, private titleService: Title) { }

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const currentUser = this._authenticationService.currentUserValue;

    if (currentUser) {
      if (localStorage.getItem('returnUrl')) {
        this._router.navigate([localStorage.getItem('returnUrl')]);
        // console.log('returnUrl', localStorage.getItem('returnUrl'));
        localStorage.removeItem('returnUrl');
      }
      // var url = state.url;
      // console.log('currentUser.datauser', currentUser.datauser);
      if (currentUser.datauser) {
        this.getPermission(currentUser.datauser, state.url).then(data => {
          if (data == 0 && state.url != '/profile') {
            this._router.navigate(['/error/not-found']);
            return false;
          } else {
            return true;
          }
        });
      }

      switch (state.url) {
        case '/profile':
          this.titleService.setTitle('ข้อมูลส่วนตัว | LIBKK88');
          break;

        default:
          break;
      }


      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this._router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  async getPermission(currentUser, url) {
    var count = 0;
    var user = currentUser;
    // console.log("user", user.role.permission);
    if (url !== '/auth/login' && url !== '/auth/register' && url !== '/error/not-authorized' && url !== '/error/not-found' && user) {
      this.menus.forEach(obj => {
        for (var i = 0; i < obj.children.length; i++) {
          if (url == obj.children[i].url) {
            this.subPermissionUrl = obj.id + "-" + obj.children[i].id;
            for (var j = 0; j < user.role.permission.length; j++) {
              if (this.subPermissionUrl == user.role.permission[j]) {
                // console.log(this.subPermissionUrl, user.role.permission[j]);
                this.titleService.setTitle(obj.children[i].title + ' | LIBKK88');
                // console.log(obj.children[i].title);
                count = 1;
              }
            }
            break;
          }
        }
      });
    }
    return count;
  }
}
