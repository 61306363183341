import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  {
    id: '0',
    title: 'Dashboard',
    type: 'item',
    // role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
    icon: 'far fa-chart-pie',
    url: '/dashboard',
    children: [
      {
        id: '0',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard'
      }
    ]
  },
  {
    id: '1',
    title: 'จัดการสมาชิก',
    type: 'item',
    icon: 'far fa-users',
    url: '/user-list',
    children: [
      {
        id: '0',
        title: 'จัดการสมาชิก',
        type: 'item',
        url: '/user-list'
      }
    ]
  },
  {
    id: '2',
    title: 'การเติมเงิน & ถอนเงิน',
    type: 'item',
    icon: 'far fa-sack-dollar',
    url: '/finance/money-in-out-lists',
    children: [
      {
        id: '0',
        title: 'การเติมเงิน & ถอนเงิน',
        type: 'item',
        url: '/finance/money-in-out-lists'
      }
    ]
  },
  {
    id: '3',
    title: 'รายงานทางการเงิน',
    type: 'collapsible',
    icon: 'far fa-file',
    children: [
      {
        id: '1',
        title: 'รายงานการเติม',
        type: 'item',
        icon: 'fas fa-circle fa-sm text-secondary',
        url: '/finance/deposit'
      },
      {
        id: '2',
        title: 'รายงานการถอน',
        type: 'item',
        icon: 'fas fa-circle fa-sm text-secondary',
        url: '/finance/withdraw'
      },
      {
        id: '3',
        title: 'รายงานปันผลหุ้นส่วน',
        type: 'item',
        icon: 'fas fa-circle fa-sm text-secondary',
        url: '/finance/shareholder'
      }
    ]
  },
  {
    id: '4',
    title: 'การตั้งค่า',
    type: 'collapsible',
    icon: 'far fa-cog',
    children: [
      {
        id: '1',
        title: 'ตั้งค่าทั่วไป',
        type: 'item',
        icon: 'fas fa-circle fa-sm text-secondary',
        url: '/settings/bonus'
      },
      {
        id: '2',
        title: 'ข้อความประกาศ',
        type: 'item',
        icon: 'fas fa-circle fa-sm text-secondary',
        url: '/settings/annoucement'
      },
      {
        id: '3',
        title: 'เกมส์ยอดนิยม',
        type: 'item',
        icon: 'fas fa-circle fa-sm text-secondary',
        url: '/settings/top-games'
      },
      {
        id: '4',
        title: 'บัญชีธนาคาร',
        type: 'item',
        icon: 'fas fa-circle fa-sm text-secondary',
        url: '/settings/bank-account'
      },
      // {
      //   id: '4',
      //   title: 'ส่วนแบ่ง Agent',
      //   type: 'item',
      //   icon: 'fas fa-circle fa-sm text-secondary',
      //   url: '/settings/share-holder'
      // },

    ]
  },
  {
    id: '5',
    title: 'ผู้ถือหุ้น',
    type: 'item',
    icon: 'far fa-sack-dollar',
    url: '/share-holder',
    children: [
      {
        id: '0',
        title: 'ผู้ถือหุ้น',
        type: 'item',
        url: '/share-holder'
      },
    ]
  },
];

