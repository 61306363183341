import { take } from 'rxjs/operators';
import { AuthenticationService } from './../../../../auth/service/authentication.service';
import { Component, OnInit } from '@angular/core';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { Socket } from 'ngx-socket-io';

// Interface
// interface notification {
//   messages: [];
//   systemMessages: [];
//   system: Boolean;
// }

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: any;
  userProfile: any;
  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(
    private _notificationsService: NotificationsService,
    private socket: Socket,
    private _authService: AuthenticationService
  ) { }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._notificationsService.onApiDataChange.subscribe(res => {
      this.notifications = res;
    });
    this._authService.currentUser.pipe(take(1)).subscribe((x: any) => {
      this.userProfile = x;
      // console.log('this.userProfile', this.userProfile);
      if (this.userProfile.datauser) {
        this.getNotiSocket();
      }
    });
  }

  getNotiSocket() {
    // socket
    this.socket.on('connect', () => {
      console.log('connect');
      // console.log(this.userProfile.datauser._id);
      this.socket.emit('join_notic', {
        id: this.userProfile.datauser._id,
        role_id: this.userProfile.datauser.role._id,
      });

      this.socket.on('join_notic', (room: any) => {
        console.log('join_notic', room);
        // console.log(room);
      });
      this.socket.on('notic', (data: any) => {
        console.log('notic', data);
        // console.log(data);
        // if (data.data) {
        //   this.notifications = data;
        // } else {
        //   this.notifications.data.push(data);
        //   this.notifications.unread_count++;
        // }
      });
    });
  }


}
