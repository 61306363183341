import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'nameById'
})
export class NameByIdPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items = array to search by _id
   * @param {string} key = _id of item
   *
   * @returns {string}
   */
  transform(key: string, items: any): string {
    if (!items) return '-';
    if (!key) return '-';

    const item = items.filter(it => {
      if (it._id == key) {
        return it.name;
      }
    });

    return item[0].name;
  }
}
