import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  getHeader // login successful if there's a jwt token in the response
    (): import("@angular/common/http").HttpHeaders | { [header: string]: string | string[]; } {
    throw new Error('Method not implemented.');
  }
  //public
  public currentUser: Observable<User>;
  public ip: any;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    // return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
    return;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    // return this.currentUser && this.currentUserSubject.value.role === Role.Client;
    return;
  }

  /**
   *  getOS
   */
  getOS() {
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("iphone") > -1) {
      return 'iPhone';
    } else if (userAgent.indexOf("ipad") > -1) {
      return 'iPad';
    } else if (userAgent.indexOf("mac os") > -1) {
      return 'Mac OS';
    } else if (userAgent.indexOf("windows") > -1) {
      return 'Windows';
    } else if (userAgent.indexOf("android") > -1) {
      return 'Android';
    } else if (userAgent.indexOf("windows phone") > -1) {
      return 'Windows Phone';
    } else if (userAgent.indexOf("linux") > -1) {
      return 'Linux';
    } else {
      return;
    }
  }

  getIP() {
    this._http.get(environment.ipUrl).toPromise().then((res: any) => {
      this.ip = res.ip;
    }).catch((error) => {
      //    console.log(error);
    });
  }

  /**
   * User login
   *
   * @param username
   * @param password
   * @returns user
   */
  login(body: any) {
    return this._http
      .post<any>(`${environment.apiUrl}/auth/admin/login`, body)
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user.status && user.data.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user.data));
            this._toastrService.success('ยินดีต้อนรับ คุณ ' + user.data.datauser.name, 'เข้าสู่ระบบสำเร็จ', {
              timeOut: 5000,
              toastClass: 'toast ngx-toastr',
              closeButton: true
            });

            this.currentUserSubject.next(user);
          } else {
            let msg = 'ลองใหม่อีกครั้ง!';
            switch (user.message) {
              case 'Password incorrect!!!':
                msg = 'รหัสผ่านไม่ถูกต้อง';
                break;

              case 'no admin':
                msg = 'ไม่พบชื่อผู้ใช้ในระบบ';
                break;

              default:
                break;
            }
            user.msg = msg;
            // setTimeout(() => {
            this._toastrService.error(msg, 'เกิดข้อผิดพลาด', {
              tapToDismiss: true,
              toastClass: 'toast ngx-toastr',
              closeButton: true
            });
            // }, 50000);

          }

          return user;
        })
      );
  }

  /**
   * Get user by id
   */
  getById(id: number) {
    return this._http.get<any>(`${environment.apiUrl}/admin/get/info/${id}`).pipe(
      map(user => {
        // login successful if there's a jwt token in the response
        if (user.status && user.data.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user.data));
          this.currentUserSubject.next(user);
        }
        return user;
      })
    );

  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    // localStorage.removeItem('currentUser');
    localStorage.clear();

    // notify
    this.currentUserSubject.next(null);
    this.router.navigate(['/auth/login']);
  }
}
