import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;
  user: any;

  @Input()
  layout = 'vertical';

  @Input()
  menu: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef, private _coreMenuService: CoreMenuService) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Set the menu either from the input or from the service
    this.menu = this.menu || this._coreMenuService.getCurrentMenu();

    // Subscribe to the current menu changes
    this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.currentUser = this._coreMenuService?.currentUser;
      if (this.currentUser) {
        this.user = this.currentUser.datauser?.role
        //    console.log(this.user.permission, 'permission');
        //sort

        // set permission on menu
        this.user.temp_main_permission = new Array();
        this.user.temp_sub_permission = new Array();

        var c = 0; // index of permission array
        // loop of menu
        for (var a = 0; a < this.menu.length; a++) {
          // set temp_sub_permission array by menu[a].children.length
          this.user.temp_sub_permission[a] = new Array(this.menu[a].children?.length);
          if (this.menu[a].children?.length > 0) {
            // loop of menu[a].children
            for (var b = 0; b < this.menu[a].children.length; b++) {
              // if permission == menu
              for (var c = 0; c < this.user.permission.length; c++) {
                if (this.user.permission[c] == this.menu[a].id + "-" + this.menu[a].children[b].id) {
                  this.user.temp_main_permission[a] = this.menu[a].id;
                  this.user.temp_sub_permission[a][b] = this.menu[a].id + "-" + this.menu[a].children[b].id;
                }
              }
            }
          }
        }

        // console.log(this.user.temp_main_permission, this.user.temp_sub_permission);
      }

      // Load menu
      this.menu = this._coreMenuService.getCurrentMenu();

      this._changeDetectorRef.markForCheck();
    });
  }
}
